var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _vm.Dlvs
        ? _c(
            "b-row",
            [
              _c("b-col", { attrs: { md: "12" } }, [
                _c(
                  "h1",
                  {
                    class: [
                      `${!_vm.$screen.sm ? "main-page-title" : ""}`,
                      { "is-pwa": _vm.$isPwa() },
                    ],
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(
                          _vm.FormMSG(130, "Deliveries for this document")
                        ) +
                        "\n\t\t\t"
                    ),
                  ]
                ),
              ]),
              _c(
                "b-col",
                { attrs: { cols: "12", xl: "12" } },
                [
                  _c(
                    "transition",
                    { attrs: { name: "slide" } },
                    [
                      _c(
                        "b-card",
                        {
                          staticClass: "card-border-blue-light form",
                          attrs: { "no-body": "" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "px-4 pt-3" },
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "d-flex justify-content-start",
                                      attrs: { sm: "12" },
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn-transparent d-flex align-items-center",
                                          on: {
                                            click: function ($event) {
                                              return _vm.$router.go(-1)
                                            },
                                          },
                                        },
                                        [
                                          _c(_vm.getLucideIcon("ArrowLeft"), {
                                            tag: "component",
                                            staticClass: "icon",
                                            attrs: {
                                              color: "rgba(6, 38, 62, 0.65)",
                                              size: 22,
                                            },
                                          }),
                                          _c("span", {
                                            staticClass:
                                              "title-back-bolder ml-1",
                                            domProps: {
                                              innerHTML: _vm._s(_vm.caption3),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "pt-3" }, [
                                _c(
                                  "fieldset",
                                  {
                                    staticClass:
                                      "scheduler-border border-groove-blue-streak pt-0",
                                  },
                                  [
                                    _c(
                                      "legend",
                                      {
                                        staticClass:
                                          "scheduler-border text-color-blue-streak",
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.FormMSG(
                                                110,
                                                "Select receivers for document"
                                              )
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                    _vm.newDoc
                                      ? _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "12",
                                                  md: "6",
                                                  lg: "6",
                                                  xl: "6",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      label: _vm.FormMSG(
                                                        15,
                                                        "Name"
                                                      ),
                                                      "label-for": "docName",
                                                    },
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        disabled: true,
                                                        id: "docName",
                                                        type: "text",
                                                      },
                                                      model: {
                                                        value: _vm.newDoc.name,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.newDoc,
                                                            "name",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "newDoc.name",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "10",
                                                  md: "5",
                                                  lg: "5",
                                                  xl: "5",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      label: _vm.FormMSG(
                                                        11,
                                                        "Type"
                                                      ),
                                                      "label-for": "docType",
                                                    },
                                                  },
                                                  [
                                                    _c("b-form-select", {
                                                      attrs: {
                                                        options:
                                                          _vm.optionsForDocType,
                                                        id: "docType",
                                                        disabled: true,
                                                        size: "md",
                                                      },
                                                      model: {
                                                        value: _vm.newDoc.type,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.newDoc,
                                                            "type",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "newDoc.type",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "2",
                                                  md: "1",
                                                  lg: "1",
                                                  xl: "1",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      label: _vm.FormMSG(
                                                        47,
                                                        "View"
                                                      ),
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "button",
                                                      {
                                                        class: {
                                                          "btn-transparent text-color-rhapsody-in-blue": true,
                                                        },
                                                        staticStyle: {
                                                          "margin-left": "-6px",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handlePreviewDocument(
                                                              _vm.curDeli
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          _vm.getLucideIcon(
                                                            "Eye"
                                                          ),
                                                          {
                                                            tag: "component",
                                                            attrs: {
                                                              size: 32,
                                                              color: "#225CBD",
                                                              "stroke-width": 1.5,
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "b-card-body",
                            [
                              _c(
                                "b-card",
                                {
                                  staticClass: "card-border-blue-light",
                                  attrs: { "no-body": "" },
                                },
                                [
                                  _c(
                                    "b-card-body",
                                    [
                                      _c("div", [
                                        _c(
                                          "fieldset",
                                          {
                                            staticClass:
                                              "scheduler-border border-groove-blue-streak pt-0",
                                          },
                                          [
                                            _c(
                                              "legend",
                                              {
                                                staticClass:
                                                  "scheduler-border text-color-blue-streak",
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(_vm.caption2) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-row",
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    attrs: {
                                                      sm: "12",
                                                      md: "8",
                                                      lg: "8",
                                                      xl: "8",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-row",
                                                      [
                                                        _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "12",
                                                              md: "8",
                                                              lg: "8",
                                                              xl: "8",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              {
                                                                attrs: {
                                                                  "label-for":
                                                                    "deliver-to-department",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "b-form-select",
                                                                  {
                                                                    attrs: {
                                                                      id: "deliver-to-department",
                                                                      options:
                                                                        _vm.mapDepartments,
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        _vm.departmentChange,
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.selectedDepartment,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.selectedDepartment =
                                                                            $$v
                                                                        },
                                                                      expression:
                                                                        "selectedDepartment",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "12",
                                                              md: "4",
                                                              lg: "4",
                                                              xl: "4",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-button",
                                                              {
                                                                attrs: {
                                                                  variant:
                                                                    "success",
                                                                  disabled:
                                                                    _vm.selectedDepartment ==
                                                                    0,
                                                                  size: "md",
                                                                  block: "",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.addDepToSelection()
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        23,
                                                                        "Add departement to selection"
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "b-row",
                                                      [
                                                        _vm.$screen.width >= 992
                                                          ? _c(
                                                              "b-col",
                                                              {
                                                                staticClass:
                                                                  "d-flex align-items-center",
                                                                class: `${
                                                                  _vm.$screen
                                                                    .width < 992
                                                                    ? "py-3"
                                                                    : ""
                                                                }`,
                                                                attrs: {
                                                                  sm: "10",
                                                                  md: "10",
                                                                  lg: "10",
                                                                  xl: "10",
                                                                },
                                                              },
                                                              [
                                                                _vm.$screen
                                                                  .width >= 992
                                                                  ? _c(
                                                                      "b-input-group",
                                                                      [
                                                                        _c(
                                                                          "b-form-input",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                type: "text",
                                                                                placeholder:
                                                                                  _vm.FormMSG(
                                                                                    26,
                                                                                    "Type to Search"
                                                                                  ),
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm.filter,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.filter =
                                                                                      $$v
                                                                                  },
                                                                                expression:
                                                                                  "filter",
                                                                              },
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "b-input-group-append",
                                                                          {
                                                                            staticClass:
                                                                              "cursor-pointer",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "b-input-group-text",
                                                                              {
                                                                                staticClass:
                                                                                  "btn-search",
                                                                              },
                                                                              [
                                                                                _vm
                                                                                  .filter
                                                                                  .length ===
                                                                                0
                                                                                  ? _c(
                                                                                      _vm.getLucideIcon(
                                                                                        "Search"
                                                                                      ),
                                                                                      {
                                                                                        tag: "component",
                                                                                        staticClass:
                                                                                          "icon",
                                                                                        attrs:
                                                                                          {
                                                                                            color:
                                                                                              "#FFFFFF",
                                                                                            size: 16,
                                                                                            "stroke-width": 2.5,
                                                                                          },
                                                                                      }
                                                                                    )
                                                                                  : _c(
                                                                                      _vm.getLucideIcon(
                                                                                        "X"
                                                                                      ),
                                                                                      {
                                                                                        tag: "component",
                                                                                        staticClass:
                                                                                          "icon",
                                                                                        attrs:
                                                                                          {
                                                                                            color:
                                                                                              "#FFFFFF",
                                                                                            size: 16,
                                                                                            "stroke-width": 2.5,
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              _vm.filter =
                                                                                                ""
                                                                                            },
                                                                                        },
                                                                                      }
                                                                                    ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm.filter === ""
                                                          ? _c(
                                                              "b-col",
                                                              {
                                                                staticClass:
                                                                  "d-flex align-items-center",
                                                                attrs: {
                                                                  sm: "2",
                                                                  md: "2",
                                                                  lg: "2",
                                                                  xl: "2",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn-transparent text-color-blue-streak",
                                                                    attrs: {
                                                                      disabled:
                                                                        _vm.filter !==
                                                                        "",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        _vm.switchCheckAll,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm.checkAll
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "d-flex align-items-center fs-14",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "pr-2",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.FormMSG(
                                                                                      16,
                                                                                      "All"
                                                                                    )
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              _vm.getLucideIcon(
                                                                                "CheckSquare"
                                                                              ),
                                                                              {
                                                                                tag: "component",
                                                                                staticClass:
                                                                                  "icon",
                                                                                attrs:
                                                                                  {
                                                                                    size: 18,
                                                                                    "stroke-width": 2,
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "d-flex align-items-center fs-14",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "pr-2",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.FormMSG(
                                                                                      17,
                                                                                      "None"
                                                                                    )
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              _vm.getLucideIcon(
                                                                                "Square"
                                                                              ),
                                                                              {
                                                                                tag: "component",
                                                                                staticClass:
                                                                                  "icon",
                                                                                attrs:
                                                                                  {
                                                                                    size: 18,
                                                                                    "stroke-width": 2,
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass:
                                                      "d-flex justify-content-center align-items-center",
                                                    attrs: {
                                                      sm: "12",
                                                      md: "4",
                                                      lg: "4",
                                                      xl: "4",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        style: {
                                                          width: `${
                                                            _vm.$screen.width >=
                                                            992
                                                              ? "50%"
                                                              : "100%"
                                                          }`,
                                                        },
                                                        attrs: {
                                                          variant:
                                                            "outline-secondary",
                                                          disabled:
                                                            _vm.Dlvs.length ==
                                                              0 ||
                                                            _vm.numItemPublished ==
                                                              _vm.numItemSelected,
                                                          size: "md",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.publishDocument()
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                27,
                                                                "Confirm your choice"
                                                              )
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { cols: "12", xl: "12" } },
                                            [
                                              _vm.$screen.width < 992
                                                ? _c("CardListBuilder", {
                                                    attrs: {
                                                      items: _vm.Dlvs,
                                                      fields: _vm.delifields,
                                                      omittedFields: [
                                                        "selected",
                                                      ],
                                                    },
                                                    on: {
                                                      "row-clicked":
                                                        _vm.rowClicked,
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "actions",
                                                          fn: function (data) {
                                                            return [
                                                              data.item
                                                                .selected &&
                                                              data.item.status >
                                                                0
                                                                ? _c(
                                                                    "b-button",
                                                                    {
                                                                      attrs: {
                                                                        variant:
                                                                          "success",
                                                                        size: "sm",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.unselectItem(
                                                                              data.item
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "icon-check",
                                                                      }),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              data.item
                                                                .selected &&
                                                              data.item
                                                                .status == 0
                                                                ? _c(
                                                                    "b-button",
                                                                    {
                                                                      attrs: {
                                                                        variant:
                                                                          "warning",
                                                                        size: "sm",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.unselectItem(
                                                                              data.item
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "icon-check",
                                                                      }),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              data.item
                                                                .selected ==
                                                              false
                                                                ? _c(
                                                                    "b-button",
                                                                    {
                                                                      attrs: {
                                                                        variant:
                                                                          "outline-primary",
                                                                        size: "sm",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.selectItem(
                                                                              data.item
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "icon-check",
                                                                      }),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      2245424099
                                                    ),
                                                  })
                                                : _vm._e(),
                                              _vm.$screen.width >= 992
                                                ? _c("b-table", {
                                                    ref: "deliveriesTable",
                                                    staticStyle: {
                                                      "text-align": "left",
                                                    },
                                                    attrs: {
                                                      "selected-variant":
                                                        "primary",
                                                      hover: "",
                                                      responsive: "sm",
                                                      selectable: "",
                                                      "sticky-header": "700px",
                                                      "select-mode": "single",
                                                      items: _vm.Dlvs,
                                                      fields: _vm.delifields,
                                                      "current-page":
                                                        _vm.currentPage,
                                                      filter: _vm.filter,
                                                      "per-page": _vm.perPage,
                                                      "row-details": "",
                                                      "filter-included-fields":
                                                        [
                                                          "fullName",
                                                          "user.departmentName",
                                                          "user.functionName",
                                                          "contractStartDate",
                                                          "contractEndDate",
                                                        ],
                                                      "head-variant": "dark",
                                                      bordered: "",
                                                      small: "",
                                                      "tbody-tr-class": "p-2",
                                                    },
                                                    on: {
                                                      "row-clicked":
                                                        _vm.rowClicked,
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "cell(selected)",
                                                          fn: function (data) {
                                                            return [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "cursor-pointer py-0",
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        size: "lg",
                                                                        disabled:
                                                                          data
                                                                            .item
                                                                            .status ===
                                                                            3 ||
                                                                          data
                                                                            .item
                                                                            .status ===
                                                                            4 ||
                                                                          data
                                                                            .item
                                                                            .status ===
                                                                            5 ||
                                                                          data
                                                                            .item
                                                                            .status ===
                                                                            7,
                                                                      },
                                                                      on: {
                                                                        change:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.selectDelivery(
                                                                              data,
                                                                              $event
                                                                            )
                                                                          },
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          data
                                                                            .item
                                                                            .selected,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              data.item,
                                                                              "selected",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "data.item.selected",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                        {
                                                          key: "cell(status)",
                                                          fn: function (data) {
                                                            return [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "wrap-status",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      class: `status ${data.item.validatedClass}`,
                                                                      staticStyle:
                                                                        {
                                                                          "font-size":
                                                                            "0.7rem",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                          _vm._s(
                                                                            data.value
                                                                          ) +
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                        {
                                                          key: "cell(senderStatus)",
                                                          fn: function (data) {
                                                            return [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "wrap-status",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      class: `status ${data.item.myValidatedClass}`,
                                                                      staticStyle:
                                                                        {
                                                                          "font-size":
                                                                            "0.7rem",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                          _vm._s(
                                                                            data.value
                                                                          ) +
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                        {
                                                          key: "cell(view)",
                                                          fn: function (data) {
                                                            return [
                                                              data.item.selected
                                                                ? _c(
                                                                    _vm.getLucideIcon(
                                                                      "Eye"
                                                                    ),
                                                                    {
                                                                      tag: "component",
                                                                      attrs: {
                                                                        color:
                                                                          "#06263E",
                                                                        size: 16,
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handlePreviewDocument(
                                                                              data.item
                                                                            )
                                                                          },
                                                                      },
                                                                    }
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          },
                                                        },
                                                        {
                                                          key: "cell(showDetails)",
                                                          fn: function (data) {
                                                            return [
                                                              data.item
                                                                .contracts
                                                                .length > 0
                                                                ? _c("div", [
                                                                    _c(
                                                                      "button",
                                                                      {
                                                                        staticClass:
                                                                          "btn-transparent",
                                                                        on: {
                                                                          click:
                                                                            data.toggleDetails,
                                                                        },
                                                                      },
                                                                      [
                                                                        !data.detailsShowing
                                                                          ? _c(
                                                                              "SvgPlus"
                                                                            )
                                                                          : _vm._e(),
                                                                        data.detailsShowing
                                                                          ? _c(
                                                                              "SvgMinus"
                                                                            )
                                                                          : _vm._e(),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                            ]
                                                          },
                                                        },
                                                        {
                                                          key: "row-details",
                                                          fn: function (data) {
                                                            return [
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-table",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "text-align":
                                                                            "left",
                                                                        },
                                                                      attrs: {
                                                                        id: "child-contract-list",
                                                                        hover:
                                                                          _vm.hover,
                                                                        responsive:
                                                                          "sm",
                                                                        selectable:
                                                                          "",
                                                                        selectedVariant:
                                                                          "primary",
                                                                        "select-mode":
                                                                          "single",
                                                                        items:
                                                                          data
                                                                            .item
                                                                            .contracts,
                                                                        fields:
                                                                          _vm.delifields,
                                                                        bordered:
                                                                          "",
                                                                        small:
                                                                          "",
                                                                        "head-variant":
                                                                          _vm.hv,
                                                                      },
                                                                      scopedSlots:
                                                                        _vm._u(
                                                                          [
                                                                            {
                                                                              key: "cell(selected)",
                                                                              fn: function (
                                                                                data
                                                                              ) {
                                                                                return [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "cursor-pointer py-0",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "b-form-checkbox",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              size: "lg",
                                                                                              disabled:
                                                                                                data
                                                                                                  .item
                                                                                                  .status ===
                                                                                                  3 ||
                                                                                                data
                                                                                                  .item
                                                                                                  .status ===
                                                                                                  4 ||
                                                                                                data
                                                                                                  .item
                                                                                                  .status ===
                                                                                                  5 ||
                                                                                                data
                                                                                                  .item
                                                                                                  .status ===
                                                                                                  7,
                                                                                            },
                                                                                          on: {
                                                                                            change:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.selectDelivery(
                                                                                                  data,
                                                                                                  $event
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                          model:
                                                                                            {
                                                                                              value:
                                                                                                data
                                                                                                  .item
                                                                                                  .selected,
                                                                                              callback:
                                                                                                function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.$set(
                                                                                                    data.item,
                                                                                                    "selected",
                                                                                                    $$v
                                                                                                  )
                                                                                                },
                                                                                              expression:
                                                                                                "data.item.selected",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ]
                                                                              },
                                                                            },
                                                                            {
                                                                              key: "cell(status)",
                                                                              fn: function (
                                                                                data
                                                                              ) {
                                                                                return [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "wrap-status",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          class: `status ${data.item.validatedClass}`,
                                                                                          staticStyle:
                                                                                            {
                                                                                              "font-size":
                                                                                                "0.7rem",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                              _vm._s(
                                                                                                data.value
                                                                                              ) +
                                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              },
                                                                            },
                                                                            {
                                                                              key: "cell(senderStatus)",
                                                                              fn: function (
                                                                                data
                                                                              ) {
                                                                                return [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "wrap-status",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          class: `status ${data.item.myValidatedClass}`,
                                                                                          staticStyle:
                                                                                            {
                                                                                              "font-size":
                                                                                                "0.7rem",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                              _vm._s(
                                                                                                data.value
                                                                                              ) +
                                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              },
                                                                            },
                                                                            {
                                                                              key: "cell(view)",
                                                                              fn: function (
                                                                                data
                                                                              ) {
                                                                                return [
                                                                                  data
                                                                                    .item
                                                                                    .selected
                                                                                    ? _c(
                                                                                        _vm.getLucideIcon(
                                                                                          "Eye"
                                                                                        ),
                                                                                        {
                                                                                          tag: "component",
                                                                                          attrs:
                                                                                            {
                                                                                              color:
                                                                                                "#06263E",
                                                                                              size: 16,
                                                                                            },
                                                                                          on: {
                                                                                            click:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.handlePreviewDocument(
                                                                                                  data.item
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                        }
                                                                                      )
                                                                                    : _vm._e(),
                                                                                ]
                                                                              },
                                                                            },
                                                                          ],
                                                                          null,
                                                                          true
                                                                        ),
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      986527137
                                                    ),
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("loading", {
                                        attrs: {
                                          active: _vm.isLoading,
                                          "is-full-page": true,
                                        },
                                        on: {
                                          "update:active": function ($event) {
                                            _vm.isLoading = $event
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }