<style scoped>
/* .card-body>>>table>tbody>tr>td {
    cursor: pointer;
}

input[type="texte"] {
    width: 100%;
    padding: 12px 20px;
    margin: 4px 0;
    box-sizing: border-box;
    border: none;
    background-color: white;
    color: white;
} */
</style>

<template>
	<errorContainer :error="erreur" :warning="warning">
		<b-row v-if="Dlvs">
			<b-col md="12">
				<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">
					{{ FormMSG(130, 'Deliveries for this document') }}
				</h1>
			</b-col>
			<b-col cols="12" xl="12">
				<transition name="slide">
					<b-card no-body class="card-border-blue-light form">
						<div class="px-4 pt-3">
							<b-row>
								<b-col sm="12" class="d-flex justify-content-start">
									<button class="btn-transparent d-flex align-items-center" @click="$router.go(-1)">
										<component :is="getLucideIcon('ArrowLeft')" color="rgba(6, 38, 62, 0.65)" :size="22" class="icon" />
										<span class="title-back-bolder ml-1" v-html="caption3" />
									</button>
								</b-col>
							</b-row>
							<div class="pt-3">
								<fieldset class="scheduler-border border-groove-blue-streak pt-0">
									<legend class="scheduler-border text-color-blue-streak">
										{{ FormMSG(110, 'Select receivers for document') }}
									</legend>
									<b-row v-if="newDoc">
										<b-col sm="12" md="6" lg="6" xl="6">
											<b-form-group :label="FormMSG(15, 'Name')" label-for="docName">
												<b-form-input v-model="newDoc.name" :disabled="true" id="docName" type="text" />
											</b-form-group>
										</b-col>
										<b-col sm="10" md="5" lg="5" xl="5">
											<b-form-group :label="FormMSG(11, 'Type')" label-for="docType">
												<b-form-select v-model="newDoc.type" :options="optionsForDocType" id="docType" :disabled="true" size="md" />
											</b-form-group>
										</b-col>
										<b-col sm="2" md="1" lg="1" xl="1">
											<b-form-group :label="FormMSG(47, 'View')">
												<button
													:class="{
														'btn-transparent text-color-rhapsody-in-blue': true
													}"
													style="margin-left: -6px"
													@click="handlePreviewDocument(curDeli)"
												>
													<component :is="getLucideIcon('Eye')" :size="32" color="#225CBD" :stroke-width="1.5" />
												</button>
											</b-form-group>
										</b-col>
									</b-row>
								</fieldset>
							</div>
						</div>
						<b-card-body>
							<b-card no-body class="card-border-blue-light">
								<b-card-body>
									<div>
										<fieldset class="scheduler-border border-groove-blue-streak pt-0">
											<legend class="scheduler-border text-color-blue-streak">
												{{ caption2 }}
											</legend>
											<b-row>
												<b-col sm="12" md="8" lg="8" xl="8">
													<b-row>
														<b-col sm="12" md="8" lg="8" xl="8">
															<b-form-group label-for="deliver-to-department">
																<b-form-select
																	id="deliver-to-department"
																	v-model="selectedDepartment"
																	:options="mapDepartments"
																	@change="departmentChange"
																/>
															</b-form-group>
														</b-col>
														<b-col sm="12" md="4" lg="4" xl="4">
															<b-button
																variant="success"
																:disabled="selectedDepartment == 0"
																size="md"
																block
																@click="addDepToSelection()"
															>
																{{ FormMSG(23, 'Add departement to selection') }}
															</b-button>
														</b-col>
													</b-row>
													<b-row>
														<b-col
															sm="10"
															md="10"
															lg="10"
															xl="10"
															v-if="$screen.width >= 992"
															class="d-flex align-items-center"
															:class="`${$screen.width < 992 ? 'py-3' : ''}`"
														>
															<b-input-group v-if="$screen.width >= 992">
																<b-form-input v-model="filter" type="text" :placeholder="FormMSG(26, 'Type to Search')" />
																<b-input-group-append class="cursor-pointer">
																	<b-input-group-text class="btn-search">
																		<component
																			:is="getLucideIcon('Search')"
																			color="#FFFFFF"
																			:size="16"
																			class="icon"
																			v-if="filter.length === 0"
																			:stroke-width="2.5"
																		/>
																		<component
																			:is="getLucideIcon('X')"
																			color="#FFFFFF"
																			:size="16"
																			class="icon"
																			:stroke-width="2.5"
																			v-else
																			@click="filter = ''"
																		/>
																	</b-input-group-text>
																</b-input-group-append>
															</b-input-group>
														</b-col>
														<b-col v-if="filter === ''" sm="2" md="2" lg="2" xl="2" class="d-flex align-items-center">
															<button
																class="btn-transparent text-color-blue-streak"
																:disabled="filter !== ''"
																@click="switchCheckAll"
															>
																<div v-if="checkAll" class="d-flex align-items-center fs-14">
																	<span class="pr-2">{{ FormMSG(16, 'All') }}</span
																	><component :is="getLucideIcon('CheckSquare')" :size="18" class="icon" :stroke-width="2" />
																</div>
																<div v-else class="d-flex align-items-center fs-14">
																	<span class="pr-2">{{ FormMSG(17, 'None') }}</span
																	><component :is="getLucideIcon('Square')" :size="18" class="icon" :stroke-width="2" />
																</div>
															</button>
														</b-col>
													</b-row>
												</b-col>
												<b-col sm="12" md="4" lg="4" xl="4" class="d-flex justify-content-center align-items-center">
													<b-button
														variant="outline-secondary"
														:disabled="Dlvs.length == 0 || numItemPublished == numItemSelected"
														size="md"
														@click="publishDocument()"
														:style="{
															width: `${$screen.width >= 992 ? '50%' : '100%'}`
														}"
													>
														{{ FormMSG(27, 'Confirm your choice') }}
													</b-button>
												</b-col>
											</b-row>
										</fieldset>
									</div>
									<b-row>
										<b-col cols="12" xl="12">
											<CardListBuilder
												v-if="$screen.width < 992"
												:items="Dlvs"
												:fields="delifields"
												:omittedFields="['selected']"
												@row-clicked="rowClicked"
											>
												<template slot="actions" slot-scope="data">
													<b-button
														v-if="data.item.selected && data.item.status > 0"
														variant="success"
														size="sm"
														@click="unselectItem(data.item)"
													>
														<i class="icon-check" />
													</b-button>
													<b-button
														variant="warning"
														v-if="data.item.selected && data.item.status == 0"
														size="sm"
														@click="unselectItem(data.item)"
													>
														<i class="icon-check" />
													</b-button>
													<b-button
														v-if="data.item.selected == false"
														variant="outline-primary"
														size="sm"
														@click="selectItem(data.item)"
													>
														<i class="icon-check" />
													</b-button>
												</template>
											</CardListBuilder>
											<b-table
												v-if="$screen.width >= 992"
												selected-variant="primary"
												hover
												responsive="sm"
												ref="deliveriesTable"
												selectable
												sticky-header="700px"
												select-mode="single"
												:items="Dlvs"
												style="text-align: left"
												:fields="delifields"
												:current-page="currentPage"
												:filter="filter"
												:per-page="perPage"
												row-details
												:filter-included-fields="[
													'fullName',
													'user.departmentName',
													'user.functionName',
													'contractStartDate',
													'contractEndDate'
												]"
												@row-clicked="rowClicked"
												head-variant="dark"
												bordered
												small
												tbody-tr-class="p-2"
											>
												<template #cell(selected)="data" class="text-center">
													<!-- <b-button variant="success" v-if="data.item.selected && data.item.status > 0" size="sm" @click="unselectItem(data.item)">
														<i class="icon-check"></i>
													</b-button>
													<b-button
														variant="warning"
														v-if="data.item.selected && data.item.status == 0"
														size="sm"
														@click="unselectItem(data.item)"
													>
														<i class="icon-check"></i>
													</b-button>
													<b-button variant="outline-primary" v-if="data.item.selected == false" size="sm" @click="selectItem(data.item)">
														<i class="icon-check"></i>
													</b-button> -->
													<div class="cursor-pointer py-0">
														<b-form-checkbox
															v-model="data.item.selected"
															size="lg"
															:disabled="
																data.item.status === 3 ||
																data.item.status === 4 ||
																data.item.status === 5 ||
																data.item.status === 7
															"
															@change="selectDelivery(data, $event)"
														/>
													</div>
												</template>
												<template #cell(status)="data">
													<div class="wrap-status">
														<div :class="`status ${data.item.validatedClass}`" style="font-size: 0.7rem">
															{{ data.value }}
														</div>
													</div>
												</template>
												<template #cell(senderStatus)="data">
													<div class="wrap-status">
														<div :class="`status ${data.item.myValidatedClass}`" style="font-size: 0.7rem">
															{{ data.value }}
														</div>
													</div>
												</template>
												<template #cell(view)="data">
													<component
														:is="getLucideIcon('Eye')"
														color="#06263E"
														v-if="data.item.selected"
														:size="16"
														@click="handlePreviewDocument(data.item)"
													/>
												</template>
												<template #cell(showDetails)="data">
													<div v-if="data.item.contracts.length > 0">
														<button class="btn-transparent" @click="data.toggleDetails">
															<SvgPlus v-if="!data.detailsShowing" />
															<SvgMinus v-if="data.detailsShowing" />
														</button>
													</div>
												</template>

												<template #row-details="data">
													<div>
														<b-table
															id="child-contract-list"
															:hover="hover"
															responsive="sm"
															selectable
															selectedVariant="primary"
															select-mode="single"
															:items="data.item.contracts"
															style="text-align: left"
															:fields="delifields"
															bordered
															small
															:head-variant="hv"
														>
															<template #cell(selected)="data" class="text-center">
																<div class="cursor-pointer py-0">
																	<b-form-checkbox
																		v-model="data.item.selected"
																		size="lg"
																		:disabled="
																			data.item.status === 3 ||
																			data.item.status === 4 ||
																			data.item.status === 5 ||
																			data.item.status === 7
																		"
																		@change="selectDelivery(data, $event)"
																	/>
																</div>
															</template>
															<template #cell(status)="data">
																<div class="wrap-status">
																	<div :class="`status ${data.item.validatedClass}`" style="font-size: 0.7rem">
																		{{ data.value }}
																	</div>
																</div>
															</template>
															<template #cell(senderStatus)="data">
																<div class="wrap-status">
																	<div :class="`status ${data.item.myValidatedClass}`" style="font-size: 0.7rem">
																		{{ data.value }}
																	</div>
																</div>
															</template>
															<template #cell(view)="data">
																<component
																	:is="getLucideIcon('Eye')"
																	color="#06263E"
																	v-if="data.item.selected"
																	:size="16"
																	@click="handlePreviewDocument(data.item)"
																/>
															</template>
														</b-table>
													</div>
												</template>
											</b-table>
										</b-col>
									</b-row>
									<loading :active.sync="isLoading" :is-full-page="true" />
								</b-card-body>
							</b-card>
						</b-card-body>
					</b-card>
				</transition>
			</b-col>
		</b-row>
	</errorContainer>
</template>

<script>
import gql from 'graphql-tag';
import { store } from '@/store';
import Loading from 'vue-loading-overlay';
import languageMessages from '@/mixins/languageMessages';
import { isNil } from '@/shared/utils';
import globalMixin from '@/mixins/global.mixin';
import * as icons from 'lucide-vue';
import moment from 'moment';
import { classDocumentStatus } from '@/shared/global-status';
import { getDocument } from '@/modules/document-package/cruds/document.crud';
import { getFileExtension, getFileSrc } from '@/shared/helpers';
import { getDocumentDeliveryList, publishDocument, addDeptToDelivery, removeUserFromDelivery, selectUserForDelivery } from '@/cruds/document.crud';
import SvgPlus from '@/components/Offers/SvgPlus';
import SvgMinus from '@/components/Offers/SvgMinus';
import _ from 'lodash';

const query_departments = gql`
	query {
		GetDepartments {
			id
			value
			message
		}
	}
`;

// const mutation_signDoc = gql`
// 	mutation ($DocumentId: ID!, $UserId: ID!, $RequesterId: ID) {
// 		SignDocument(DocumentId: $DocumentId, UserId: $UserId, RequesterId: $RequesterId)
// 	}
// `

export default {
	name: 'DocumentDeliveries',
	components: {
		Loading,
		SvgPlus,
		SvgMinus
	},
	mixins: [languageMessages, globalMixin],
	props: {
		caption1: {
			type: String,
			default: 'Publishing document:'
		},

		hover: {
			type: Boolean,
			default: true
		},
		striped: {
			type: Boolean,
			default: true
		},
		bordered: {
			type: Boolean,
			default: true
		},
		small: {
			type: Boolean,
			default: false
		},
		fixed: {
			type: Boolean,
			default: false
		}
	},
	data: () => {
		return {
			hv: 'dark',
			erreur: {},
			Dlvs: [],
			Deps: [],
			curDep: 0,
			newDoc: {},
			curDeli: {},
			currentPage: 1,
			perPage: 0,
			warning: '',
			filter: '',
			isLoading: false,
			newCreated: 0,
			stopPropagation: '',
			selectMode: 'single',
			selectedColor: 'primary',
			successModal: false,
			checkPasswordModal: false,
			successModalDelete: false,
			timeZoneStr: '',
			timeZoneHourDif: 0,
			selectedDepartment: 0,
			numItemSelected: 0,
			numItemPublished: 0,
			changeManyRecord: false,
			passwordInput: '',
			userIdSelected: 0,
			name: '',
			nameState: null,
			passwordInput: '',
			passwordState: null,
			useDigElecSign: false,
			selectedItem: null,
			checkAll: true
		};
	},
	computed: {
		delifields() {
			let fields = [
				{
					key: 'selected',
					label: this.FormMSG(40, 'Selected'),
					formatter: (v) => {
						return v;
					},
					sortable: false,
					class: 'text-center w-90-px'
				},
				{
					key: 'fullName',
					label: this.FormMSG(50, 'Name'),
					sortable: true
				},
				{
					key: 'user.departmentName',
					label: this.FormMSG(41, 'Department'),
					sortable: true,
					class: 'w-125-px'
				},
				{
					key: 'user.functionName',
					label: this.FormMSG(42, 'Function'),
					sortable: true,
					class: 'w-125-px'
				},
				{
					key: 'contractStartDate',
					label: this.FormMSG(18, 'Contract start date'),
					formatter: (v) => moment(v).format('DD/MM/YYYY'),
					sortable: true,
					class: 'w-140-px'
				},
				{
					key: 'contractEndDate',
					label: this.FormMSG(19, 'Contract end date'),
					formatter: (v) => {
						const value = v.split('T')[0];
						return moment(value).format('DD/MM/YYYY');
					},
					sortable: true,
					class: 'w-140-px'
				},
				{
					key: 'status',
					label: this.FormMSG(46, 'User Status'),
					formatter: (value) => {
						return this.GetTextFromMenuNumberAndMenuValue(1012, value); // 0 not published, 1 not received, 2 received, 3 read, 4 signed
					},
					sortable: false,
					class: 'text-center w-125-px'
				},
				{
					key: 'senderStatus',
					label: this.FormMSG(43, 'Production Status'),
					formatter: (value, key, item) => {
						return this.getLabelStep(item.status, value, item.currentStep); // 0 not published, 1 not received, 2 received, 3 read, 4 signed
					},
					sortable: false,
					class: 'text-center w-125-px'
				},
				{
					key: 'view',
					label: this.FormMSG(28, 'View'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center w-90-px'
				}
			];

			if (store.state.curDocument.type === 9 || store.state.curDocument.type === 11) {
				fields.unshift({
					key: 'showDetails',
					label: '',
					class: 'text-center w-35-px'
				});
			}

			return fields;
		},
		optionsForDocType() {
			return this.FormMenu(1011);
		},
		caption2() {
			// compute number of selected elements
			var numItemFound = 0;
			this.numItemPublished = 0;
			for (var i = 0; i < this.Dlvs.length; i++) {
				const element = this.Dlvs[i];
				if (element.selected) {
					numItemFound += 1;
				}
				if (element.status > 0) {
					this.numItemPublished += 1;
				}
				for (var j = 0; j < element.contracts.length; j++) {
					const contract = element.contracts[j];
					if (contract.selected) {
						numItemFound += 1;
					}
					if (contract.status > 0) {
						this.numItemPublished += 1;
					}
				}
			}
			this.numItemSelected = numItemFound;
			//console.log("print i:", numItemFound);
			var myCaption = this.FormMSG(112, 'Receivers selected') + ': ' + numItemFound;

			return myCaption;
		},
		caption3() {
			var newCaption = !isNil(this.newDoc) ? this.newDoc.name : '';
			return this.FormMSG(13, 'Back to') + ' &laquo;' + newCaption + '&raquo;';
		},
		mapDepartments() {
			//console.log("in mapDepartments this.Deps", this.Deps);
			const departments = this.Deps.map((d) => {
				return {
					value: d.value,
					text: d.message
				};
			});
			return [
				{
					value: 0,
					text: this.FormMSG(14, 'Select a department')
				},
				...departments
			];
		}
	},
	created() {
		if (this.timeZoneStr.length === 0) {
			this.firstTimeInitialisation();
		}
		this.reloadData();
	},
	methods: {
		getLabelStep(status, senderStatus, currentStep) {
			if (senderStatus === 5) {
				return this.GetTextFromMenuNumberAndMenuValue(1012, senderStatus);
			}
			if (currentStep === 0) {
				return this.GetTextFromMenuNumberAndMenuValue(1012, senderStatus);
			}

			if (currentStep === 1) {
				return this.GetTextFromMenuNumberAndMenuValue(1012, senderStatus) + ' ' + this.FormMSG(84, 'step 1');
			}
			if (currentStep === 2) {
				return this.GetTextFromMenuNumberAndMenuValue(1012, senderStatus) + ' ' + this.FormMSG(85, 'step 2');
			}
			if (currentStep === 3) {
				return this.GetTextFromMenuNumberAndMenuValue(1012, senderStatus) + ' ' + this.FormMSG(86, 'step 3');
			}
		},
		goBack() {
			this.$router.go(-1);
		},
		async publishDocument() {
			try {
				var DocumentId = parseInt(this.newDoc.id, 10);

				const result = await publishDocument(DocumentId);

				// this.Dlvs = result;
				this.fillDlvs(result);

				this.createToastForMobile(this.FormMSG(96, 'Success'), this.FormMSG(97, 'Document published successfully'));
			} catch (e) {
				console.log(e);
				//this.erreur = error
				var myerrorObject = {
					err: ''
				};
				myerrorObject.err = e.message;
				this.erreur = myerrorObject;
			}
		},
		selectItem(item) {
			item.selected = true;
			//console.log("entering selectItem Item :", item);
			//console.log("entering selectItem Dlvs :", this.Dlvs);
			this.selectUserForDelivery(item.user.id, item.contractId);
		},
		unselectItem(item) {
			//console.log("entering selectItem Item  before:", item);
			item.selected = false;
			item.status = 0;
			item._cellVariants = {
				status: this.receivedColor(item.status)
			};
			//this.fillDlvs();
			//console.log("entering selectItem Item after:", item);
			//console.log("entering unselectItem Dlvs :", this.Dlvs);
			this.removeUserFromDelivery(item);
		},
		departmentChange(departement) {
			this.curDep = departement;
		},
		addAllToDelivery() {
			this.selectUserForDelivery(-1, 0);
			return;
		},
		removeAllFromDelivery() {
			this.selectUserForDelivery(0, 0);
			return;
		},
		async addDepToSelection() {
			try {
				var DocumentId = parseInt(this.newDoc.id, 10);
				var DepartmentNumber = parseInt(this.selectedDepartment, 10);

				const result = await addDeptToDelivery(DocumentId, DepartmentNumber);

				// this.Dlvs = result;
				this.fillDlvs(result);

				this.createToastForMobile(this.FormMSG(96, 'Success'), this.FormMSG(100, 'Users department selected successfully'));

				this.selectedDepartment = 0;
			} catch (error) {
				console.log(error);
				var myerrorObject = {
					err: ''
				};
				myerrorObject.err = error.message;
				this.erreur = myerrorObject;

				this.selectedDepartment = 0;
			}
		},
		loadDeps() {
			this.$apollo
				.query({
					query: query_departments,
					variables: {},
					// options: { fetchPolicy: 'network-only' },
					// options: { fetchPolicy: 'no-cache' },
					// force update from server
					fetchPolicy: 'network-only'
				})
				.then((result) => {
					this.Deps = result.data.GetDepartments;
					//console.log("deps:", this.Deps);
				})
				.catch((error) => {
					console.log(error);
					this.erreur = error;
				});
		},
		setTimeZoneStr() {
			// getting timezone
			if (this.timeZoneStr.length > 0) {
				return;
			}
			var d = new Date();
			var n = d.getTimezoneOffset();
			if (n < 0) {
				var hOffset = -n / 60;
				var sign = '+';
			} else {
				var hOffset = n / 60;
				var sign = '-';
			}
			var BASE = '00';
			this.timeZoneStr = hOffset ? sign + BASE.substr(0, 2 - Math.ceil(hOffset / 10)) + hOffset : sign + BASE;
			this.timeZoneStr = this.timeZoneStr + ':00';
			this.timeZoneHourDif = hOffset;
		},
		firstTimeInitialisation() {
			this.setTimeZoneStr();
			this.newDoc = store.state.curDocument;
			//console.log("this.newDoc in initialisation:", this.newDoc)
			this.loadDeps();
		},
		receivedColor(status) {
			//console.log("color:", status);
			if (status == 1) {
				var retval = 'danger';
			} else if (status == 2) {
				var retval = 'warning';
			} else if (status == 3) {
				var retval = 'primary';
			} else if (status == 4) {
				var retval = 'success';
			} else {
				var retval = 'grey';
			}

			return retval;
		},
		fillDlvs(documentDeliveries) {
			let results = [];

			for (let i = 0; i < documentDeliveries.length; i++) {
				let element = documentDeliveries[i];
				if ((!element.user.name && !element.user.firstName) || (element.user.name.length === 0 && element.user.firstName.length === 0)) {
					element.fullName = element.user.email;
				} else {
					element.fullName = element.user.name + ' ' + element.user.firstName;
				}

				element.validatedClass = classDocumentStatus(element.status);
				element.myValidatedClass = classDocumentStatus(element.senderStatus);

				// results.push(element);
				if (i === 0) {
					this.$set(element, 'contracts', []);
					this.$set(element, '_showDetails', false);
					results.push(element);
				} else {
					const findIndexOf = results.findIndex((r) => parseInt(r.user.id) === parseInt(element.user.id));
					if (findIndexOf === -1) {
						this.$set(element, 'contracts', []);
						this.$set(element, '_showDetails', false);
						results.push(element);
					} else {
						if (!results[findIndexOf]._showDetails) {
							results[findIndexOf]._showDetails = element.selected;
						}

						results[findIndexOf].contracts.push(element);
					}
				}
			}

			for (let i = 0; i < results.length; i++) {
				if (results[i].contracts.length > 0) {
					results[i] = this.sortContractDate(results[i]);
				}
			}

			this.Dlvs = results;
		},
		async reloadData() {
			try {
				let DocumentId = parseInt(this.newDoc.id, 10);

				const result = await getDocumentDeliveryList(DocumentId);

				//this.Dlvs = result;
				this.fillDlvs(result);
				if (this.isAllChecked()) {
					this.checkAll = !this.checkAll;
				}
			} catch (err) {
				console.log(err);
				this.erreur = err;
			}
		},
		isAllChecked() {
			let checked = true;
			for (const delivery of this.Dlvs) {
				if (!delivery.selected) {
					checked = false;
					break;
				}
			}

			return checked;
		},
		async selectUserForDelivery(userId, contractId) {
			try {
				let DeliveryDetail = [
					{
						userId: parseInt(userId, 10),
						contractId: parseInt(contractId, 10)
					}
				];
				if (DeliveryDetail[0].userId <= 0) {
					this.changeManyRecord = true;
				}
				let DocumentId = parseInt(this.newDoc.id);

				const result = await selectUserForDelivery(DocumentId, DeliveryDetail);

				if (this.changeManyRecord) {
					// this.Dlvs = result;
					this.Dlvs = [];
					this.fillDlvs(result);
					this.changeManyRecord = false;
				}
			} catch (error) {
				console.log(error);
				this.changeManyRecord = false;
				this.erreur = error;
			}
		},
		async removeUserFromDelivery(item) {
			try {
				let ContractId = parseInt(item.contractId, 10);
				let UserId = parseInt(item.user.id, 10);
				let DocumentId = parseInt(this.newDoc.id, 10);

				await removeUserFromDelivery(DocumentId, UserId, ContractId);
			} catch (error) {
				console.log(error);
				this.erreur = error;
			}
		},
		getRowCount(items) {
			//console.log(items)
			return items.length;
		},
		rowClicked(item) {
			this.curDeli = item;
		},
		getLucideIcon(name) {
			return icons[name];
		},
		switchCheckAll() {
			if (this.checkAll) {
				this.selectUserForDelivery(-1, 0);
			} else {
				this.selectUserForDelivery(0, 0);
			}
			this.checkAll = !this.checkAll;
		},
		selectDelivery(data, payload) {
			// console.log(data)
			if (payload) {
				this.selectUserForDelivery(data.item.user.id, data.item.contractId);
				data.item.userId = data.item.user.id;
			} else {
				data.item.status = 0;
				this.removeUserFromDelivery(data.item);
				this.Dlvs[data.index].validatedClass = classDocumentStatus(0);
			}
		},
		viewDocument(item) {
			// console.log({item})
		},
		async handlePreviewDocument(item) {
			const { id } = this.$route.params;

			await getDocument(parseInt(id, 10)).then((record) => {
				let fileList = [];

				if (record.type === 11) {
					// store.state.myProfile.id
					fileList = record.childDocList.filter((list) => list.userId === null || parseInt(list.userId, 10) === 0);

					fileList = fileList.map((doc) => {
						const src = getFileSrc(doc, {
							contractId: item.contractId,
							requestId: store.userID(),
							userId: parseInt(item.user.id, 10)
						});

						return {
							...doc,
							src: src + '?onlyViewDocument=true&deliveryParentId=' + doc.firstDeliveryParentId,
							thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + doc.xid,
							ext: getFileExtension(doc.fileName),
							isHovered: false
						};
					});
				} else {
					const src = getFileSrc(record, {
						contractId: item.contractId,
						requestId: store.userID(),
						userId: parseInt(item.user.id, 10)
					});

					fileList.push({
						fileName: record.fileName,
						type: record.type,
						xid: record.xid,
						status: record.status,
						toSign: record.toSign,
						src: src + '?onlyViewDocument=true&deliveryParentId=' + record.firstDeliveryParentId,
						thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + record.xid,
						ext: getFileExtension(record.fileName),
						isHovered: false
					});
				}

				this.$previewImages({
					images: fileList,
					focusIndex: 0,
					options: {
						presentationMode: 'doc',
						// showStatusFileTag: true,
						hideCommentButton: false,
						hideDisLikeButton: false,
						hideLikeDislike: false,
						hideCropButton: true,
						hideDeleteButton: true,
						showSignFileButton: true
					}
				});
			});
		},
		sortContractDate(item) {
			let contracts = [];
			let result = {};
			let firstValue = item;
			let arr = [firstValue, ...item.contracts];

			contracts = arr.sort((b, a) => {
				const startDateB = new Date(b.contractStartDate);
				const startDateA = new Date(a.contractStartDate);
				if (startDateA.getTime() < startDateB.getTime()) {
					return -1;
				}
				if (startDateA.getTime() > startDateB.getTime()) {
					return 1;
				}
				return 0;
			});

			const findIndexCurrContract = contracts.findIndex((r) => {
				const startDateTime = new Date(r.contractStartDate).getTime();
				const endDateTime = new Date(r.contractEndDate).getTime();
				const now = Date.now();

				if (now >= startDateTime && now <= endDateTime) {
					return true;
				}

				return false;
			});

			let dataToUpdateItem = {};

			if (findIndexCurrContract > -1) {
				dataToUpdateItem = contracts[findIndexCurrContract];
				contracts.splice(findIndexCurrContract, 1);
			} else {
				dataToUpdateItem = contracts[0];
				contracts.splice(0, 1);
			}
			dataToUpdateItem.contracts = contracts;

			return dataToUpdateItem;
		}
	}
};
</script>
